import React from 'react';
import Phone2 from './../img/phone2.png';
import './Contact.css';
import { Link } from 'react-router-dom';
import facebook from '../img/HopClean/facebook.png';

import instargram from '../img/HopClean/instagram.png';
import ticktock from '../img/HopClean/tiktok.png';

function FooterH34() {
  return (
    <div className="footerH34">
      <h4>Wladek Clean</h4>
      <h3>Czyszczenie mebli</h3>
    </div>
  );
}
export function TelFooter() {
  return (
    <div className="telFooter">
      <Link className="phoneFooter" to="tel:+48513313818">
        {' '}
        <img src={Phone2} alt="no painting" /> <span>+48 513 313 818</span>
      </Link>
      <div className="linksFooter">
        <Link to="https://www.facebook.com/WladekCleanPl" target="_blanket">
          <img src={facebook} alt="facebook" title="facebook" />
        </Link>

        <Link
          to="https://www.instagram.com/wladek_clean/profilecard/?igsh=MWtlNDloZzhybWE5Ng=="
          target="_blanket"
        >
          <img src={instargram} alt="instagram" title="instagram" />
        </Link>
        <Link
          to="https://www.tiktok.com/@wladek_clean?is_from_webapp=1&sender_device=pc
https://www.tiktok.com/@wladek_clean?is_from_webapp=1&sender_device=pc"
          target="_blanket"
        >
          <img src={ticktock} alt="ticktock" title="ticktock" />
        </Link>
      </div>
    </div>
  );
}
function Contact() {
  return (
    <div className="contact">
      <FooterH34 />
      <TelFooter />
    </div>
  );
}

export default Contact;
