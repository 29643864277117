import React, { useState } from 'react';
import './TelSubmit.css';
import BackDropForm from './BackDropForm';
import Phone2 from '../img/phone2.png';
import { Link } from 'react-router-dom';
import facebook from '../img/HopClean/facebook.png';
// import youtube from '../img/HopClean/youtube.png';
import instargram from '../img/HopClean/instagram.png';
import ticktock from '../img/HopClean/tiktok.png';
import list from '../img/list.png';

export function Social() {
  return (
    <div className="links">
      <Link to="https://www.facebook.com/WladekCleanPl" target="_blanket">
        <img src={facebook} alt="facebook" title="facebook" />
      </Link>
      {/* <Link to="#" target="_blanket">
        <img src={youtube} alt="youtube" title="youtube" />
      </Link> */}
      <Link
        to="https://www.instagram.com/wladek_clean/profilecard/?igsh=MWtlNDloZzhybWE5Ng=="
        target="_blanket"
      >
        <img src={instargram} alt="instagram" title="instagram" />
      </Link>
      <Link
        to="https://www.tiktok.com/@wladek_clean?is_from_webapp=1&sender_device=pc
https://www.tiktok.com/@wladek_clean?is_from_webapp=1&sender_device=pc"
        target="_blanket"
      >
        <img src={ticktock} alt="ticktock" title="ticktock" />
      </Link>
    </div>
  );
}
export function Planwork() {
  return (
    <div className="planwork">
      <img src={list} alt="list" />
      <span>6:00-22:00/7</span>
    </div>
  );
}

export function Tel() {
  return (
    <div className="tel">
      <Link to="tel:+48513313818">
        {' '}
        <img src={Phone2} alt="no painting" /> <span>+48 513 313 818</span>
      </Link>
    </div>
  );
}

export function PlanSocial() {
  return (
    <div className="socialplanwork">
      <Planwork />
      <Tel />
    </div>
  );
}

function Submit({ onOpen = (f) => f }) {
  return (
    <div className="submit">
      <button
        onClick={() => {
          onOpen(true);
        }}
        id="btn"
        className="reg_btn hidden"
      >
        Zostaw prośbę
      </button>
    </div>
  );
}

function TelSubmit() {
  const [isSignINBackdrop, setSignInBackdrop] = useState(false);

  if (isSignINBackdrop) {
    document.querySelector('body').style.overflow = 'hidden';
  } else {
    document.querySelector('body').style.overflow = 'visible';
  }

  const onOpenBackdrop = () => {
    setSignInBackdrop(true);
  };
  return (
    <>
      {/* <div className="telSubmit"> */}
      <PlanSocial />
      <Submit onOpen={onOpenBackdrop} />
      <BackDropForm
        setOpen={() => setSignInBackdrop(false)}
        isOpen={isSignINBackdrop}
        handleClick={() => setSignInBackdrop(false)}
      />
      {/* </div> */}
    </>
  );
}

function LangTel() {
  const [isScrollUp, setScrollUp] = useState(false);

  window.addEventListener('scroll', function () {
    let scrolled = window.pageYOffset;
    if (scrolled > 170) {
      setScrollUp(true);
    }
    if (scrolled < 170) {
      setScrollUp(false);
    }
  });

  return (
    <>
      <div className={isScrollUp ? 'fixedlangTel' : 'langTel'}>
        <Social />
        <TelSubmit />
      </div>
    </>
  );
}

export default LangTel;
